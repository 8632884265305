<template>
  <div>
    <div
      class="container tw-flex tw-flex-col tw-justify-center tw-w-full tw-m-auto tw-z-20 tw-relative tw-max-w-5xl"
      :class="{ 'md:tw-mb-24': user.requests.length <= 1 }"
    >
      <div class="tw-relative tw-mx-2 tw-mt-6 tw-mb-8">
        <router-link
          to="/sq/client/matching"
          class="tw-text-[#292929] tw-text-sm tw-font-medium tw-leading-snug tw-flex tw-flex-row"
        >
          <img
            loading="lazy"
            src="@/assets/images/icons/arrow-back.svg"
            class="me-3 btn-back-img"
          />
         
           {{ $t("signup.back") }}
        </router-link>
      </div>
      <div
        class="tw-flex tw-justify-center tw-flex-1 md:tw-gap-20 tw-mx-2 tw-bg-white tw-rounded-lg tw-p-8"
      >
        <section
          :class="[
            'container sign-up mx-auto px-0 row d-flex flex-lg-row justify-content-center flex-nowrap flex-column-reverse align-items-center mt-3',
            className,
          ]"
        >
          <div class="px-0 one-step-sign-up-form col-lg-12">
            <form @submit.prevent="submit">
              <div class="requestsInfo">
                <h3>
                  {{ $t("signup.editRequest") }}
                </h3>
                <div v-if="isLoaded && feIsLoaded && beIsLoaded" class="editRequest__disclaimer tw-mt-2 tw-mb-4 tw-p-3 tw-rounded-lg tw-bg-[#ffffff]">
                  <!-- <div class="editRequest__disclaimer--header">
                    <h4>
                      #4342321
                    </h4>
                    <p>
                      <span>
                        {{ submissionDate }}
                      </span>
                    </p>
                  </div> -->
                  <p class="tw-text-sm tw-pe-4 tw-text-[#878484]">
                    {{ $t("matchedTalents.submissionNotice") }}
                  </p>
                </div>
                <div v-if="!isLoaded || !feIsLoaded || !beIsLoaded" class="tw-my-8 tw-flex tw-items-center tw-justify-center">
                  <div class="edit-request-data-loader"></div>
                </div>
                <template v-if="isLoaded && feIsLoaded && beIsLoaded">
                  <div
                    v-for="(request, index) in user.requests"
                    :key="index"
                    class="requestsInfo__request md:tw-mb-4 lg:tw-mb-4"
                  >
                    <div
                      class="tw-flex tw-flex-col md:tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-2"
                    >
                      <button
                        v-if="index <= (user.requests.length - 1) && user.requests.length > 1"
                        class="tw-block md:tw-hidden tw-text-3xl tw-ms-auto tw-p-3"
                        type="button"
                        @click="removeRequest(index)"
                      >
                        &times;
                      </button>
                      <div
                        class="requestsInfo__input md:tw-col-span-2 lg:tw-col-span-4"
                      >
                        <label class="form-label required">{{
                          $t("clientWizard.chooseRole")
                        }}</label>
                        <v-select
                          v-model="user.requests[index].role_id.$model"
                          :reduce="(name) => name.id"
                          class="style-chooser en_dropdown"
                          :class="{
                            'option-selected':
                              user.requests[index].role_id.$model,
                          }"
                          :placeholder="$t('clientWizard.chooseRole')"
                          :options="rolesList"
                          :selectable="(option) => !option.group"
                          @input="showUpLanguageFields(index)"
                          label="name"
                        >
                          <template #option="{ id, name, group, isCustom }">
                            <div
                              v-if="group"
                              class="requestsInfo__option tw-flex tw-gap-1 tw-items-center !tw-w-full"
                              disabled
                            >
                              {{ group }}
                            </div>
                            <div
                              v-else-if="isCustom && name"
                              class="requestsInfo__option--sticky tw-flex tw-gap-1 tw-items-center !tw-w-full tw-pt-2"
                            >
                              <img
                                src="@/assets/images/register-information.svg"
                                alt="Info icon"
                              />
                              {{ name }}
                            </div>
                            <div
                              v-else-if="id && name"
                              class="requestsInfo__option !tw-w-full"
                            >
                              {{ name }}
                            </div>
                          </template>
                          <div slot="no-options">
                            {{ $t("talent.sorryNoMatchingOptions") }}
                          </div>
                        </v-select>
                        <div class="error-select-validation-message">
                          <VulidateError
                            :validationField="user.requests[index].role_id"
                            :params="['required']"
                          />
                        </div>
                      </div>
                      <div>
                        <label class="form-label required">{{
                          $t("clientWizard.chooseAvailability")
                        }}</label>
                        <v-select
                          v-model="user.requests[index].availability.$model"
                          :reduce="(name) => name.id"
                          class="style-chooser"
                          :placeholder="$t('clientWizard.chooseAvailability')"
                          :options="availabilitiesList"
                          label="name"
                          @input="requestAvailabilityChanged(index)"
                        >
                          <div slot="no-options">
                            {{ $t("talent.sorryNoMatchingOptions") }}
                          </div>
                        </v-select>
                        <div class="error-select-validation-message">
                          <VulidateError
                            :validationField="user.requests[index].availability"
                            :params="['required']"
                          />
                        </div>
                      </div>
                      <div>
                        <label class="form-label required">{{
                          $t("clientWizard.chooseDuration")
                        }}</label>
                        <v-select
                          v-model="user.requests[index].duration.$model"
                          :reduce="(name) => name.id"
                          class="style-chooser"
                          :placeholder="$t('clientWizard.chooseDuration')"
                          :options="user.requests[index].durationsList"
                          label="name"
                        >
                          <div slot="no-options">
                            {{ $t("talent.sorryNoMatchingOptions") }}
                          </div>
                        </v-select>
                        <div class="error-select-validation-message">
                          <VulidateError
                            :validationField="user.requests[index].duration"
                            :params="['required']"
                          />
                        </div>
                      </div>
                      <div
                        class="tw-col-span-6 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-2"
                      >
                        <div
                          v-if="
                            user.requests[index].hasFirstLanguage &&
                            user.requests[index].firstLanguageCode == 'backend'
                          "
                        >
                          <label class="form-label required">{{
                            $t("clientWizard.choosePrimaryLanguage")
                          }}</label>
                          <v-select
                            v-model="user.requests[index].be_lang_id.$model"
                            :reduce="(name) => name.id"
                            class="style-chooser"
                            :placeholder="
                              $t('clientWizard.choosePrimaryLanguage')
                            "
                            :options="backendLanguages"
                            label="name"
                            @input="updateFirstLanguage(index, $event)"
                          >
                            <div slot="no-options">
                              {{ $t("talent.sorryNoMatchingOptions") }}
                            </div>
                          </v-select>
                          <div class="error-select-validation-message">
                            <VulidateError
                              :validationField="user.requests[index].be_lang_id"
                              :params="['required']"
                            />
                          </div>
                        </div>
                        <div
                          v-else-if="
                            user.requests[index].hasFirstLanguage &&
                            user.requests[index].firstLanguageCode == 'frontend'
                          "
                        >
                          <label class="form-label required">{{
                            $t("clientWizard.choosePrimaryLanguage")
                          }}</label>
                          <v-select
                            v-model="user.requests[index].fe_lang_id.$model"
                            :reduce="(name) => name.id"
                            class="style-chooser"
                            :placeholder="
                              $t('clientWizard.choosePrimaryLanguage')
                            "
                            :options="frontendLanguages"
                            label="name"
                            @input="updateFirstLanguage(index, $event)"
                          >
                            <div slot="no-options">
                              {{ $t("talent.sorryNoMatchingOptions") }}
                            </div>
                          </v-select>
                          <div class="error-select-validation-message">
                            <VulidateError
                              :validationField="user.requests[index].fe_lang_id"
                              :params="['required']"
                            />
                          </div>
                        </div>
                        <div v-if="user.requests[index].hasSecondLanguage">
                          <label class="form-label required">{{
                            $t("clientWizard.chooseSecondaryLanguage")
                          }}</label>
                          <v-select
                            v-model="user.requests[index].fe_lang_id.$model"
                            :reduce="(name) => name.id"
                            class="style-chooser"
                            :placeholder="
                              $t('clientWizard.chooseSecondaryLanguage')
                            "
                            :options="frontendLanguages"
                            label="name"
                            @input="updateSecondLanguage(index, $event)"
                          >
                            <div slot="no-options">
                              {{ $t("talent.sorryNoMatchingOptions") }}
                            </div>
                          </v-select>
                          <div class="error-select-validation-message">
                            <VulidateError
                              :validationField="user.requests[index].fe_lang_id"
                              :params="['required']"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      v-if="index == (user.requests.length - 1) || user.requests.length == 1"
                      class="add tw-hidden md:tw-flex tw-items-center tw-justify-center tw-w-32"
                      type="button"
                      @click="addNewRequest"
                    >
                      <img
                        class="tw-me-2"
                        src="@/assets/images/Ic_add-fill.svg"
                        alt="Add request"
                      />

                      <span
                        class="tw-text-[#422e87] tw-text-base tw-font-semibold tw-leading-normal"
                        >
                          {{ $t("signup.newRole") }}
                        </span
                      >
                    </button>
                    <button
                      v-else
                      class="add tw-hidden md:tw-flex tw-w-32 tw-justify-center"
                      type="button"
                      @click="removeRequest(index)"
                    >
                      <img
                        class="tw-me-2"
                        src="@/assets/images/signup-trash.svg"
                        alt="Remove request"
                      />
                      <span
                        class="tw-text-[#474355] tw-text-base tw-font-semibold tw-leading-normal"
                        > {{ $t("signup.delete") }}</span
                      >
                    </button>
                  </div>

                  <button
                    class="tw-mt-4 tw-border tw-flex tw-items-center tw-justify-center tw-w-full tw-gap-2 md:tw-hidden tw-py-2 tw-rounded-md"
                    type="button"
                    @click="addNewRequest"
                  >
                    <img
                      src="@/assets/images/Ic_add-fill.svg"
                      alt="Add request"
                    />
                    {{ $t("signup.addRequest") }}
                  </button>
                </template>
              </div>

              <div
                class="mt-2 tw-flex tw-flex-col-reverse md:tw-flex-row tw-justify-end tw-gap-2 tw-mt-2 md:tw-mt-0"
              >
                <router-link
                  to="/sq/client/matching"
                  class="md:tw-w-32 tw-w-full md:tw-h-14 tw-h-10 tw-rounded-md tw-font-semibold tw-text-base tw-flex tw-items-center tw-justify-center"
                >
                  {{ $t("signup.cancel") }}
                </router-link>
                <button
                  class="md:tw-w-32 tw-w-full md:tw-h-14 tw-h-10 tw-bg-[#FFC629] tw-rounded-md tw-font-semibold tw-text-base tw-flex tw-items-center tw-justify-center"
                  :class="{ 'tw-cursor-not-allowed': !validForm || !isLoaded || !feIsLoaded || !beIsLoaded }"
                  :disabled="!validForm || !isLoaded || !feIsLoaded || !beIsLoaded"
                >
                  {{ $t("signup.save") }}
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import VulidateError from "@/components/shared/VulidateError/vulidateError.vue";
import "vue-select/dist/vue-select.css";
import moment from "moment";

export default {
  name: "ClientEditRequest",
  components: {
    VulidateError,
  },
  props: ["className", "closeModal", "isAlMostDone"],
  data: function () {
    return {
      isModalVisible: false,
      isLoaded: false,
      beIsLoaded: false,
      feIsLoaded: false,
      submissionDate: null,
      remainingOTP: null,
      rolesList: [],
      backendLanguages: [],
      frontendLanguages: [],
      availabilitiesList: [
        {
          id: 2,
          name: this.$t("clientWizard.fullTime"),
        },
        {
          id: 1,
          name: this.$t("clientWizard.partTime"),
        },
      ],
      durationsList: [
        {
          id: 6,
          name: this.$t("clientWizard.sixMonths"),
        },
        {
          id: 12,
          name: this.$t("clientWizard.twelveMonths"),
        },
        {
          id: 24,
          name: this.$t("clientWizard.twentyFourMonths"),
        },
        {
          id: 36,
          name: this.$t("clientWizard.thirtySixMonths"),
        },
      ],
      user: {
        requests: [
        ],
      },
    };
  },
  validations() {
    return {
      user: {
        requests: {
          $each: {
            availability: { required },
            duration: { required },
            be_lang_id: { required },
            fe_lang_id: { required },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      getUser: "auth/getUser",
      activeProject: "client/getActiveProject",
    }),
    validForm() {
      const requestsValid = this.user.requests.every((request) => {
        const id = request.role_id.$model;
        const beLangValid = request.be_lang_id.$model !== null && request.be_lang_id.$model !== undefined;
        const feLangValid = request.fe_lang_id.$model !== null && request.fe_lang_id.$model !== undefined;
        const availabilityValid = request.availability.$model !== null;
        const durationValid =  request.duration.$model !== null;

        if (id === 1) {
          return beLangValid && availabilityValid && durationValid;
        } else if (id === 2) {
          return feLangValid && availabilityValid && durationValid;
        } else if (id === 3) {
          return beLangValid && feLangValid && availabilityValid && durationValid;
        }
        return true;
      });

      return this.requestsAreValid && !this.$v.$invalid && requestsValid;
    },
    requestsAreValid() {
      let isValid = true;
      this.user.requests.forEach((request) => {
        if (isValid) {
          const availabilityValid = !!request.availability.$model;
          const durationValid = !!request.duration.$model;
          isValid = availabilityValid && durationValid;
        } else {
          return;
        }
      });
      return isValid;
    },
  },
  watch: {
    "$i18n.locale"() {
     // const custom = this.rolesList.find((role) => role.id === "custom");

     /*  custom.name =
        this.$i18n.locale == "en"
          ? "Not sure (Need advice)"
          : "مو متأكد، أحتاج إلى استشارة"; */

      this.user.requests = [];

      this.availabilitiesList = [
        {
          id: 2,
          name: this.$t("clientWizard.fullTime"),
        },
        {
          id: 1,
          name: this.$t("clientWizard.partTime"),
        },
      ];
      this.durationsList = [
        {
          id: 6,
          name: this.$t("clientWizard.sixMonths"),
        },
        {
          id: 12,
          name: this.$t("clientWizard.twelveMonths"),
        },
        {
          id: 24,
          name: this.$t("clientWizard.twentyFourMonths"),
        },
        {
          id: 36,
          name: this.$t("clientWizard.thirtySixMonths"),
        },
      ];
      
      this.getProjectData();
    },
    activeProject() {
      this.user.requests = [];
      this.getProjectData();
    },
  },
  async mounted() {
    await this.getProjectData();
    await this.getRolesList();
    await this.getBackendLanguages();
    await this.getFrontendLanguages();
  },
  methods: {
    async getRoles() {

        this.isLoaded = false;
        await this.axios
          .get("/api/client/projects/matching/roles?filtered=false")
          .then((res) => {
            const requests = res.data.data.map((request, i) => {
              if (!request.id) return;
              const id = request.id;
              this.addNewRequest();

              this.$set(this.user.requests[i]?.role_id, "$model", id);
              this.$set(
                this.user.requests[i].be_lang_id,
                "$model",
                request.be_lang?.id
              );
              this.$set(
                this.user.requests[i].fe_lang_id,
                "$model",
                request.fe_lang?.id
              );

              if (this.user.requests[i].availability.$model == 1) {
                this.user.requests[i].durationsList = [
                  {
                    id: 3,
                    name: this.$t("clientWizard.threeMonths"),
                  },
                  {
                    id: 6,
                    name: this.$t("clientWizard.sixMonths"),
                  },
                  {
                    id: 12,
                    name: this.$t("clientWizard.twelveMonths"),
                  },
                  {
                    id: 24,
                    name: this.$t("clientWizard.twentyFourMonths"),
                  },
                  {
                    id: 36,
                    name: this.$t("clientWizard.thirtySixMonths"),
                  },
                ]
              } else {
                this.user.requests[i].durationsList = [
                  {
                    id: 6,
                    name: this.$t("clientWizard.sixMonths"),
                  },
                  {
                    id: 12,
                    name: this.$t("clientWizard.twelveMonths"),
                  },
                  {
                    id: 24,
                    name: this.$t("clientWizard.twentyFourMonths"),
                  },
                  {
                    id: 36,
                    name: this.$t("clientWizard.thirtySixMonths"),
                  },
                ]
              }

              if (id === 1) {
                this.$set(
                  this.user.requests[i],
                  "hasFirstLanguage",
                  true
                );
                this.$set(
                  this.user.requests[i],
                  "hasSecondLanguage",
                  false
                );
                this.$set(
                  this.user.requests[i],
                  "firstLanguageCode",
                  "backend"
                );
              } else if (id === 2) {
                this.$set(
                  this.user.requests[i],
                  "hasFirstLanguage",
                  true
                );
                this.$set(
                  this.user.requests[i],
                  "hasSecondLanguage",
                  false
                );
                this.$set(
                  this.user.requests[i],
                  "firstLanguageCode",
                  "frontend"
                );
              } else if (id === 3) {
                this.$set(
                  this.user.requests[i],
                  "firstLanguageCode",
                  "backend"
                );
                this.$set(
                  this.user.requests[i],
                  "hasFirstLanguage",
                  true
                );
                this.$set(
                  this.user.requests[i],
                  "hasSecondLanguage",
                  true
                );
              } else {
                this.$set(
                  this.user.requests[i],
                  "hasFirstLanguage",
                  false
                );
                this.$set(
                  this.user.requests[i],
                  "hasSecondLanguage",
                  false
                );
              }
            });

            if (requests.length < 1) {
              this.addNewRequest();
            }
          }).catch((err) => {
            console.error(err);
          })
          .finally(() => {
            this.isLoaded = true;
          });
    },
    async getProjectData() {
        this.isLoaded = false;
        this.axios
          .get("/api/client/projects")
          .then(async (res) => {
            const data = res.data.data;
            const submissionDate = moment(data.submission_date).format("DD/MM/YYYY [at] HH:mm a");
            if (data.signup_requests.length) {
              const requests = data.signup_requests.map((request, index) => {
                const id = request.role == null ? "custom" : request.role.id;
                if (this.user.requests[index]?.role_id)
                  this.$set(this.user.requests[index]?.role_id, "$model", id);
                else {
                  this.addNewRequest();
                  this.$set(this.user.requests[index]?.role_id, "$model", id);
                }

                this.$set(
                  this.user.requests[index].availability,
                  "$model",
                  request.availability?.id
                );
                this.$set(
                  this.user.requests[index].duration,
                  "$model",
                  request?.duration
                );

                this.$set(
                  this.user.requests[index].be_lang_id,
                  "$model",
                  request.be_lang?.id
                );
                this.$set(
                  this.user.requests[index].fe_lang_id,
                  "$model",
                  request.fe_lang?.id
                );

                if (this.user.requests[index].availability.$model == 1) {
                  this.user.requests[index].durationsList = [
                    {
                      id: 3,
                      name: this.$t("clientWizard.threeMonths"),
                    },
                    {
                      id: 6,
                      name: this.$t("clientWizard.sixMonths"),
                    },
                    {
                      id: 12,
                      name: this.$t("clientWizard.twelveMonths"),
                    },
                    {
                      id: 24,
                      name: this.$t("clientWizard.twentyFourMonths"),
                    },
                    {
                      id: 36,
                      name: this.$t("clientWizard.thirtySixMonths"),
                    },
                  ]
                } else {
                  this.user.requests[index].durationsList = [
                    {
                      id: 6,
                      name: this.$t("clientWizard.sixMonths"),
                    },
                    {
                      id: 12,
                      name: this.$t("clientWizard.twelveMonths"),
                    },
                    {
                      id: 24,
                      name: this.$t("clientWizard.twentyFourMonths"),
                    },
                    {
                      id: 36,
                      name: this.$t("clientWizard.thirtySixMonths"),
                    },
                  ]
                }

                if (id === 1) {
                  this.$set(
                    this.user.requests[index],
                    "hasFirstLanguage",
                    !!request.be_lang
                  );
                  this.$set(
                    this.user.requests[index],
                    "hasSecondLanguage",
                    !!request.fe_lang
                  );
                  this.$set(
                    this.user.requests[index],
                    "firstLanguageCode",
                    "backend"
                  );
                } else if (id === 2) {
                  this.$set(
                    this.user.requests[index],
                    "hasFirstLanguage",
                    !!request.be_lang
                  );
                  this.$set(
                    this.user.requests[index],
                    "hasSecondLanguage",
                    !!request.fe_lang
                  );
                  this.$set(
                    this.user.requests[index],
                    "firstLanguageCode",
                    "frontend"
                  );
                } else if (id === 3) {
                  this.$set(
                    this.user.requests[index],
                    "firstLanguageCode",
                    "backend"
                  );
                  this.$set(
                    this.user.requests[index],
                    "hasFirstLanguage",
                    !!request.be_lang
                  );
                  this.$set(
                    this.user.requests[index],
                    "hasSecondLanguage",
                    !!request.fe_lang
                  );
                } else {
                  this.$set(
                    this.user.requests[index],
                    "hasFirstLanguage",
                    !!request.be_lang
                  );
                  this.$set(
                    this.user.requests[index],
                    "hasSecondLanguage",
                    !!request.fe_lang
                  );
                }
              });

              if (submissionDate) this.submissionDate = submissionDate;

              if (requests.length < 1) {
                this.addNewRequest();
              }
            } else { 
               await this.getRoles();
            }

          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            this.isLoaded = true;
          });
    },
    addNewRequest() {
      this.user.requests.push({
        role_id: {},
        availability: {},
        duration: {},
        be_lang_id: { $model: null },
        fe_lang_id: { $model: null },
        durationsList: [
          {
            id: 6,
            name: this.$t("clientWizard.sixMonths"),
          },
          {
            id: 12,
            name: this.$t("clientWizard.twelveMonths"),
          },
          {
            id: 24,
            name: this.$t("clientWizard.twentyFourMonths"),
          },
          {
            id: 36,
            name: this.$t("clientWizard.thirtySixMonths"),
          },
        ]
      });
    },
    requestAvailabilityChanged(index) {
      this.$set(this.user.requests[index].duration, "$model", null);
      if (this.user.requests[index].availability.$model === 1) {
        this.user.requests[index].durationsList = [
          {
            id: 3,
            name: this.$t("clientWizard.threeMonths"),
          },
          {
            id: 6,
            name: this.$t("clientWizard.sixMonths"),
          },
          {
            id: 12,
            name: this.$t("clientWizard.twelveMonths"),
          },
          {
            id: 24,
            name: this.$t("clientWizard.twentyFourMonths"),
          },
          {
            id: 36,
            name: this.$t("clientWizard.thirtySixMonths"),
          },
        ];
      } else {
        this.user.requests[index].durationsList = [
          {
            id: 6,
            name: this.$t("clientWizard.sixMonths"),
          },
          {
            id: 12,
            name: this.$t("clientWizard.twelveMonths"),
          },
          {
            id: 24,
            name: this.$t("clientWizard.twentyFourMonths"),
          },
          {
            id: 36,
            name: this.$t("clientWizard.thirtySixMonths"),
          },
        ];
      }
    },
    showUpLanguageFields(index) {
      const id = this.user.requests[index].role_id.$model;
      this.$set(this.user.requests[index].be_lang_id, "$model", null);
      this.$set(this.user.requests[index].fe_lang_id, "$model", null);

      if (id === 1) {
        this.$set(this.user.requests[index], "hasFirstLanguage", true);
        this.$set(this.user.requests[index], "hasSecondLanguage", false);
        this.$set(this.user.requests[index], "firstLanguageCode", "backend");
      } else if (id === 2) {
        this.$set(this.user.requests[index], "hasFirstLanguage", true);
        this.$set(this.user.requests[index], "hasSecondLanguage", false);
        this.$set(this.user.requests[index], "firstLanguageCode", "frontend");
      } else if (id === 3) {
        this.$set(this.user.requests[index], "firstLanguageCode", "backend");
        this.$set(this.user.requests[index], "hasFirstLanguage", true);
        this.$set(this.user.requests[index], "hasSecondLanguage", true);
      } else {
        this.$set(this.user.requests[index], "hasFirstLanguage", false);
        this.$set(this.user.requests[index], "hasSecondLanguage", false);
      }
    },
    updateFirstLanguage(index, value) {
      if (this.user.requests[index].firstLanguageCode === "backend") {
        this.$set(this.user.requests[index].be_lang_id, "$model", value);
      } else {
        this.$set(this.user.requests[index].fe_lang_id, "$model", value);
      }
    },
    updateSecondLanguage(index, value) {
      this.$set(this.user.requests[index].fe_lang_id, "$model", value);
    },
    removeRequest(index) {
      this.user.requests.splice(index, 1);
    },
    async getRolesList() {
      try {
        await this.axios.get("/api/staticContent/roles").then((res) => {
          this.rolesList = res.data;
          // this.rolesList.push({ id: 49, name: "No Code" });
          this.rolesList.push({
            id: "custom",
            name:
              this.$i18n.locale == "en"
                ? "Not sure (Need advice)"
                : "مو متأكد، أحتاج إلى استشارة",
            isCustom: true,
          });
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getBackendLanguages() {
      this.beIsLoaded = false;
      try {
        await this.axios
          .get("/api/staticContent/backendLanguages")
          .then((res) => {
            this.backendLanguages = res.data.data;
          });
      } catch (error) {
        console.error(error);
      } finally {
        this.beIsLoaded = true;
      }
    },
    async getFrontendLanguages() {
      this.feIsLoaded = false;
      try {
        await this.axios
          .get("/api/staticContent/frontendLanguages")
          .then((res) => {
            this.frontendLanguages = res.data.data;
          });
      } catch (error) {
        console.error(error);
      } finally {
        this.feIsLoaded = true;
      }
    },
    async submit() {
      if (!this.validForm) {
        return;
      }
      let loader = this.$loading.show({});
      const requests = this.user.requests.map((request) => {
        return {
          role_id:
            request.role_id.$model == "custom" ? null : request.role_id.$model,
          availability: request.availability.$model ? request.availability.$model : null ,
          duration: request.duration.$model ? request.duration.$model : null,
          be_lang_id: request.be_lang_id?.$model,
          fe_lang_id: request.fe_lang_id?.$model,
        };
      });

      this.axios
        .post("/api/client/projects/signupRequests", {
          signup_requests: requests,
        })
        .then((res) => {
          res;
          this.$router.push({ path: "/sq/client/matching" });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>

<style scoped src="@/pages/signup/signup.css"></style>
<style lang="scss">
.sign-up .one-step-sign-up-form input::disabled {
  border: 1px solid #9f9f9f;
  background-color: light-dark(rgb(229 229 229), rgba(59, 59, 59, 0.3));
}

.editRequest__disclaimer {
  border: 1px solid #E2E0E0;
  box-shadow: 0px 15px 30px 0px #00000009;

  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4 {
      font-size: 20px;
      font-weight: 600;
      color: #292929;
    }
    p {
      font-size: 12px;
      color: #292929;
      direction: ltr!important;
      
      @media (min-width: 768px) {
        font-size: 14px;
      }
    }
  }
}

.country-selector__list.slide-enter,
    .country-selector__list.slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transition-duration: 0s !important;
  display: none;
  opacity: 0;
}

.country-selector__list.slide-enter-active,
.country-selector__list.slide-leave-active {
  transition-duration: 0s !important;
  display: none;
  opacity: 0;
}

.one-step-sign-up-form .v-select > div:first-child {
  padding: 4px 0 4px !important;
  border-radius: 4px !important;
  min-height: 39px;
}

.one-step-sign-up-form .v-select input::placeholder {
  font-size: 14px;
}

.one-step-sign-up-form .vs__selected-options {
  .vs__selected + .vs__search {
    height: 0 !important;
    margin: 0;
    border: 0;
  }
}

.one-step-sign-up-form .vs__search {
  margin: 0;
}

.en_dropdown .vs__dropdown-option {
  text-align: left;
  min-width: 280px !important;
  margin-inline-end: auto;
}

.one-step-sign-up-form .vs__dropdown-option .requestsInfo__option {
  display: flex;
  width: 100%;
  color: #fff !important;
}

.services {
  &__service div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border: 1px solid #e6e7e8;
    color: #6a6a6a;
    font-size: 12px;
    border-radius: 4px;
    padding: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    user-select: none;

    &.selected {
      font-weight: 600;
      border: 1px solid #422e87;
      color: #292929;
    }
  }
}

.requestsInfo {
  h3 {
    font-size: 24px;
    font-weight: 600;
    color: #292929;
    margin-bottom: 10px;
    background-color: #f1edff;
    display: inline-block;
  }

  .vs__dropdown-menu {
    .vs__dropdown-option * {
      color: #292929 !important;
    }

    .vs__dropdown-option:not(.vs__dropdown-option--disabled):not(
        :has(.requestsInfo__option--sticky)
      ):hover
      * {
      color: #fff !important;
    }

    .vs__dropdown-option--highlight .requestsInfo__option {
      width: 100% !important;
      color: #fff !important;
    }

    .vs__dropdown-option--selected {
      background-color: #73a6f2;
      color: #fff !important;
    }

    .vs__dropdown-option--selected * {
      color: #fff !important;
    }
  }

  &__request {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding-bottom: 16px;
    border-bottom: 1px solid #d9d9d9;

    & > div {
      flex: 1;
    }
    .add {
      background-color: #fff;
      border: 1px solid #422e8726;
      border-radius: 7px;
      padding: 8px;
      cursor: pointer;
      margin-top: 24px;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__input {
    width: 100%;
  }
}

.v-select {
  direction: ltr !important;
}

html[dir="rtl"] .v-select input {
  text-align: end !important;
}

.option-selected .vs__selected-options {
  max-width: calc(100% - 54px);

  .vs__selected {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (max-width: 768px) {
    width: 260px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.en_dropdown .vs__dropdown-option--disabled {
  background-color: #e7e7e7;
  color: #292929;
  &:hover {
    background-color: #e7e7e7;
    color: #292929;
  }
}
.vs__dropdown-option {
  position: relative;
}

.vs__dropdown-menu :has(.requestsInfo__option--sticky) {
  position: sticky;
  bottom: -2px;
  background-color: white;
  z-index: 1;
  border-top: 1px solid #ccc;
}

.edit-request-data-loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid rgba(255, 198, 41, 1);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
